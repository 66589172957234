import {
  AfterViewChecked,
  Component,
  OnInit,
  OnDestroy,
  inject,
} from '@angular/core';
import { Status } from './_enum/status';
import { Subject, tap } from 'rxjs';
import { AppState } from './_store/app.state';
import { Store } from '@ngrx/store';
import { Router, RouterOutlet } from '@angular/router';
import { GlobalService } from './_services/global.service';
import { ButtonComponentComponent } from './Component/button-component/button-component.component';
import { ToastComponent } from './Component/toast/toast.component';
import { PusherService } from './_services/pusher.service';
import { routeAnimations } from './_animations/route-animations';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{ outcome: string }>;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [ButtonComponentComponent, RouterOutlet, ToastComponent],
  animations: [routeAnimations],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly pusherService = inject(PusherService);
  title = 'front';
  message: string;
  status: Status | null;
  deferredPrompt: BeforeInstallPromptEvent | null = null;
  showInstallButton = false;
  targetPages: string[] = [
    '/home',
    '/discussion',
    '/user-account/parameters/notifications',
    '/discussion-message/',
    '/account',
    '/user-account/parameters/information',
    '/event/',
    '/tickets',
    'commande/event/',
    'user-account/parameters/confidentiality',
    'user-account/parameters',
    'user-account/parameters/feedback',
    'notifications',
    'likes',
    'search',
    'search-map',
    'events-maps',
  ];
  isMobileTargetPage: boolean = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private globalService: GlobalService
  ) {
    this.store
      .select((state) => state.app.toast)
      .pipe(
        tap((toast) => {
          this.message = toast?.message ?? '';
          this.status = toast?.status ?? null;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    if (this.globalService.isMobileDevice()) {
      window.addEventListener('beforeinstallprompt', (event: Event) => {
        const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
        beforeInstallPromptEvent.preventDefault();
        this.deferredPrompt = beforeInstallPromptEvent;
        this.showInstallButton = true;
      });
    }

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js')
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      });
    } else {
      console.warn('Service Workers are not supported in this browser.');
    }
  }

  ngAfterViewChecked(): void {
    if (!this.globalService.isMobileDevice()) {
      this.isMobileTargetPage = this.isTargetPage(this.router.url);
      if (this.isMobileTargetPage) {
        this.router.navigate(['/organizer/home-organizer']);
      }
    }
  }

  isTargetPage(url: string): boolean {
    return this.targetPages.some((page) => {
      if (page.includes(':')) {
        const regex = new RegExp(`^${page}`);
        return regex.test(url);
      } else {
        return url === page;
      }
    });
  }

  installPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then(() => {
        this.deferredPrompt = null;
        this.showInstallButton = false;
      });
    }
  }

  cancelInstall() {
    this.showInstallButton = false;
    this.deferredPrompt = null;
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}
